import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import {
  formatFinance,
  getShortRequisiteData,
  getLkAlias,
} from "../common/utils";
import Pagination from "./Pagination";
import { AcceptIcon, ArchiveIcon } from "./Icons";
import ModalOtpAccept from "./modal/ModalOtpAccept";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import useDayjsFormat from "../hooks/useDayjsFormat";

const TradeDeals = () => {
  const [modalActive, setModalActive] = useState(false);
  const [dealId, setDealId] = useState(null);
  const [dealState, setDealState] = useState(null);
  const [pageTradeDeals, setPageTradeDeals] = useState(1);
  const [trigger, setTrigger] = useState(0);
  const count = 20;

  const dealStatus = ["Ожидает", "Завершено"];

  const getDealStatus = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < dealStatus.length) {
      return dealStatus[index];
    } else {
      return "В архиве";
    }
  };

  const dealStyles = [
    "btn-innactive btn-xs btn-warning",
    "btn-innactive btn-xs btn-innactive_success",
  ];

  const getDealStyle = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < dealStyles.length) {
      return dealStyles[index];
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: "/trader/deals",
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          page: pageTradeDeals,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageTradeDeals, count, trigger, modalActive]);

  const handleChangeDealStatus = (dealId, dealState) => {
    setDealId(dealId);
    setDealState(dealState);
    setModalActive(true);
  };

  let pageCountQuery = Math.ceil(data?.countTraderDeals / count);

  if (loading)
    return (
      <div style={{ display: "flex" }}>
        <Spinner />
      </div>
    );

  return (
    <>
      <ModalOtpAccept
        active={modalActive}
        setActive={setModalActive}
        dealId={dealId}
        state={dealState}
      />
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Сделки
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>ЛК</th>
                  <th>Метод</th>
                  <th>Реквизиты</th>
                  <th>Точная сумма</th>
                  <th>Время</th>
                  <th>Статус</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {data?.traderDeals.map(
                  ({
                    id,
                    id_alias,
                    bank,
                    payment_id,
                    method_group,
                    real_amount,
                    note,
                    bank_profile_id,
                    createdAt,
                    status,
                  }) => (
                    <tr key={id}>
                      <td data-label="Payment ID">{payment_id}</td>
                      <td data-label="ЛК">{getLkAlias(bank, id_alias)}</td>
                      <td data-label="Метод">{method_group}</td>
                      <td data-label="Реквизиты">
                        {getShortRequisiteData(note)}
                      </td>
                      <td data-label="Точная сумма">
                        {formatFinance(real_amount)} ₽
                      </td>
                      <td data-label="Время">
                        {getFullTimeMsk(createdAt)} <br />
                        {getFullDateMsk(createdAt)}
                      </td>
                      <td data-label="Статус">
                        <span
                          className={getDealStyle(status)}
                          onClick={() => {
                            if (status === 0) {
                              //handleChangeDealStatus(id, "close");
                            }
                          }}
                        >
                          {getDealStatus(status)}
                        </span>
                      </td>
                      <td data-label="Действия">
                        {status <= 0 && (
                          <button
                            className="btn btn-xs btn-success btn_minimal"
                            onClick={() => {
                              handleChangeDealStatus(id, "close");
                            }}
                          >
                            <AcceptIcon />
                          </button>
                        )}

                        {status === 0 && (
                          <button
                            className="btn btn-xs btn-draft btn_minimal"
                            onClick={() => {
                              handleChangeDealStatus(id, "archive");
                            }}
                          >
                            <ArchiveIcon />
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageTradeDeals}
          setPage={setPageTradeDeals}
          pageCountQuery={pageCountQuery}
        />
      }
    </>
  );
};

export default TradeDeals;
